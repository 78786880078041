/* eslint-disable camelcase */
import {
	GiTowel,
	GiPillow,
	GiWashingMachine,
	GiSpiralBottle,
	GiCableStayedBridge,
	GiPingPongBat,
	GiPoolTableCorner,
	GiConsoleController,
	GiDesk,
	GiGasStove,
	GiToaster,
	GiCookingPot,
	GiTeapotLeaves,
	GiCctvCamera,
	GiFirstAidKit,
	GiGate,
	GiThermometerCold,
	GiThermometerHot,
	GiFireplace,
	GiWoodenChair,
	GiHomeGarage,
	GiDoorway,
	GiSofa,
	GiMountains,
	GiShower,
	GiPartyPopper,
	GiWoodenDoor,
} from "react-icons/gi";
import { TbBottle, TbHanger, TbApps, TbBooks, TbBeach } from "react-icons/tb";
import {
	FaBath,
	FaShower,
	FaTv,
	FaWifi,
	FaUtensils,
	FaFireExtinguisher,
	FaFan,
	FaBox,
	FaParking,
	FaUmbrellaBeach,
	FaSwimmingPool,
	FaHotTub,
	FaWater,
	FaWheelchair,
	FaChild,
} from "react-icons/fa";
import { BiBlanket, BiCloset } from "react-icons/bi";
import {
	MdIron,
	MdLocalLaundryService,
	MdSpeaker,
	MdMicrowave,
	MdOutlineMicrowave,
	MdOutlineCoffeeMaker,
	MdDining,
	MdCrib,
	MdElectricCar,
	MdFence,
	MdOutdoorGrill,
	MdBeachAccess,
	MdBalcony,
	MdWater,
	MdElevator,
	MdPets,
} from "react-icons/md";
import {
	CgSmartHomeRefrigerator,
	CgSmartHomeWashMachine,
} from "react-icons/cg";
import { RiAlarmWarningFill } from "react-icons/ri";
import { IoMdBonfire } from "react-icons/io";
import { GrLounge } from "react-icons/gr";

export const MAGIC_NUMBERS = {
	ZERO: 0,
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
	FIVE: 5,
	REVIEW_LENGTH: 1000,
	MESSAGE_LENGTH: 2500,
	BIO_LENGTH: 4000,
	PHONE_LENGTH: 10,
	MIN_PROFILE_PHOTO_SIZE: 1, //1MB
	MAX_PROFILE_PHOTO_SIZE: 5, //MB
	BYTE_TO_MB: 1e6,
	SERVER_ERROR_CODES: [404, 500],
};

export const DEFAULT_PAGINATION_LIMIT = 11;

export const PLANS = {
	prod_La5g3PKJwMmwzK: "PREMIUM",
	prod_La5g7rXay2nqt3: "LOCAL DISCOUNT",
	prod_La5fuMlkuQWIl0: "BASIC",
	prod_La5fU3DTh1M2dr: "COHATCH",
};

export const ADMINTYPE = {
	MASTER_APPROVAL: "MASTER_APPROVAL",
	MARKET_LEADER: "MARKET_LEADER",
	SUPERADMIN: "SUPERADMIN",
	COMMUNITY_MANAGER: "COMMUNITY_MANAGER",
	TECHNICAL_SUPPORT: "TECHNICAL_SUPPORT",
	LEADERSHIP: "LEADERSHIP",
};

export const REMINDERS = [
	{ value: "subscription", label: "Subscription" },
	{ value: "email_verification", label: "Email Verification" },
	{ value: "create_listing", label: "Create Listing" },
	{ value: "profile", label: "Profile Information" },
	{ value: "basic_plan", label: "Basic Plan" },
];

export const REMINDERS_DUE_DATES = {
	subscription: 10,
	email_verification: 10,
	create_listing: 10,
	profile: 10,
	basic_plan: 30,
};

export const COHATCH_MARKETS = {
	market: [
		{ value: "atlanta", label: "Atlanta" },
		{ value: "charlotte", label: "Charlotte" },
		{ value: "cincinnati", label: "Cincinnati" },
		{ value: "cleveland", label: "Cleveland" },
		{ value: "columbus", label: "Columbus" },
		{ value: "indianapolis", label: "Indianapolis" },
		{ value: "pittsburgh", label: "Pittsburgh" },
		{ value: "springfield", label: "Springfield" },
		{ value: "tampa_bay", label: "Central Florida" },
	],
};

export const APP_STATUS = [
	{ value: "pending", label: "Pending" },
	{ value: "approved", label: "Approved" },
	{ value: "denied", label: "Denied" },
];
export const APP_LOCATIONS = {
	columbus: {
		locations: [
			{ value: "upper_arlington", label: "Upper Arlington" },
			{ value: "easton", label: "Easton" },
			{ value: "polaris", label: "Polaris" },
			{ value: "gateway", label: "The Gateway" },
			{ value: "delaware", label: "Delaware" },
			{ value: "dublin", label: "Dublin" },
			{ value: "westerville", label: "Westerville" },
			{ value: "worthington", label: "Worthington" },
			{ value: "bexley", label: "Bexley" },
		],
	},
	springfield: {
		locations: [{ value: "springfield", label: "Springfield" }],
	},
	cincinnati: {
		locations: [
			{ value: "covington", label: "Covington" },
			{ value: "hyde_park", label: "Hyde Park" },
			{ value: "mason", label: "Mason" },
			{ value: "milford", label: "Milford" },
			{ value: "kenwood", label: "Kenwood" },
			{ value: "over_the_rhine", label: "Over The Rhine (OTR)" },
		],
	},
	cleveland: {
		locations: [
			{ value: "beachwood", label: "Beachwood" },
			{ value: "ohio_city", label: "Ohio City" },
		],
	},

	indianapolis: {
		locations: [
			{ value: "broad_ripple", label: "Broad Ripple" },
			{ value: "carmel", label: "Carmel" },
			{ value: "circle_centre", label: "Circle Centre" },
			{ value: "noblesville", label: "Noblesville" },
			{ value: "zionsville", label: "Zionsville" },
			{ value: "polk_stables", label: "Polk Stables" },
		],
	},

	pittsburgh: {
		locations: [
			{ value: "waterfront", label: "Waterfront" },
			{ value: "southside_works", label: "Southside Works" },
			{ value: "shadyside", label: "Shadyside" },
		],
	},
	tampa_bay: {
		locations: [
			{ value: "lakeland", label: "Lakeland" },
			{ value: "st_petersburg", label: "St. Petersburg" },
			{ value: "west tampa", label: "West Tampa" },
			{ value: "tarpon springs", label: "Tarpon Springs" },
		],
	},
	charlotte: {
		locations: [
			{ value: "3rd_ward", label: "3RD Ward" },
			{ value: "belmont", label: "Belmont" },
			{ value: "camp_greene", label: "Camp Greene" },
			{ value: "camp_north_end", label: "Camp North End" },
			{ value: "wesley_heights", label: "Wesley Heights" },
		],
	},
	atlanta: {
		locations: [{ value: "duluth", label: "Duluth" }],
	},
};

export const COHATCH_LOCATIONS = {
	cities: [
		{
			columbus: {
				locations: [
					{ value: "upper_arlington", label: "Upper Arlington" },
					{ value: "easton", label: "Easton" },
					{ value: "polaris", label: "Polaris" },
					{ value: "gateway", label: "The Gateway" },
					{ value: "delaware", label: "Delaware" },
					{ value: "springfield", label: "Springfield" },
					{ value: "dublin", label: "Dublin" },
					{ value: "westerville", label: "Westerville" },
					{
						value: "worthington_library",
						label: "Worthington - The Library & The Madery",
					},
					{
						value: "worthington_hardware",
						label: "Worthington - The Hardware Store",
					},
				],
			},
			cincinnati: {
				locations: [
					{ value: "covington", label: "Covington" },
					{ value: "hyde_park", label: "Hyde Park" },
					{ value: "mason", label: "Mason" },
					{ value: "milford", label: "Milford" },
					{ value: "kenwood", label: "Kenwood" },
				],
			},
			cleveland: {
				locations: [
					{ value: "beachwood", label: "Beachwood" },
					{ value: "ohio_city", label: "Ohio City" },
				],
			},

			indianapolis: {
				locations: [
					{ value: "broad_ripple", label: "Broad Ripple" },
					{ value: "carmel", label: "Carmel" },
					{ value: "circle_centre", label: "Circle Centre" },
					{ value: "noblesville", label: "Noblesville" },
					{ value: "zionsville", label: "Zionsville" },
				],
			},

			pittsburgh: {
				locations: [
					{ value: "waterfront", label: "Waterfront" },
					{ value: "shadyside", label: "Shadyside" },
					{ value: "southside_works", label: "Southside works" },
				],
			},

			tampa_bay: {
				locations: [
					{ value: "lakeland", label: "Lakeland" },
					{ value: "st_petersburg", label: "St. Petersburg" },
					{ value: "tarpon springs", label: "Tarpon Springs" },
				],
			},
			charlotte: {
				locations: [
					{ value: "3rd_ward", label: "3RD Ward" },
					{ value: "belmont", label: "Belmont" },
					{ value: "camp_greene", label: "Camp Green" },
					{ value: "camp_north_end", label: "Camp North End" },
					{ value: "wesley_heights", label: "Wesley Heights" },
				],
			},
		},
	],
};

export const LOCATIONS = [
	{ value: "3rd_ward", label: "3RD Ward" },
	{ value: "beachwood", label: "Beachwood" },
	{ value: "belmont", label: "Belmont" },
	{ value: "bexley", label: "Bexley" },
	{ value: "broad ripple", label: "Broad Ripple" },
	{ value: "camp_north_end", label: "Camp North End" },
	{ value: "carmel", label: "Carmel" },
	{ value: "covington", label: "Covington" },
	{ value: "delaware", label: "Delaware" },
	{ value: "downtown-indy", label: "Downtown Indy" },
	{ value: "dublin", label: "Dublin" },
	{ value: "duluth", label: "Duluth" },
	{ value: "easton", label: "Easton" },
	{ value: "gateway", label: "Gateway" },
	{ value: "hyde_park", label: "Hyde Park" },
	{ value: "kenwood", label: "Kenwood" },
	{ value: "lakeland", label: "Lakeland" },
	{ value: "mason", label: "Mason" },
	{ value: "milford", label: "Milford" },
	{ value: "noblesville", label: "Noblesville" },
	{ value: "ohio_city", label: "Ohio City" },
	{ value: "over_the_rhine", label: "Over The Rhine (OTR)" },
	{ value: "polaris", label: "Polaris" },
	{ value: "polk_stables", label: "Polk Stables" },
	{ value: "shadyside", label: "The Shadyside" },
	{ value: "southside-works", label: "Southside Works" },
	{ value: "springfield", label: "Springfield" },
	{ value: "st. petersburg", label: "St. Petersburg" },
	{ value: "tarpon springs", label: "Tarpon Springs" },
	{ value: "upper arlingtion", label: "Upper Arlingtion" },
	{ value: "waterfront", label: "Waterfront" },
	{ value: "wesley heights", label: "Wesley Heights" },
	{ value: "west tampa", label: "West Tampa" },
	{ value: "westerville", label: "Westerville" },
	{ value: "worthington-hardware", label: "Worthingtion (Hardware)" },
	{ value: "worthington-library", label: "Worthingtion (Library)" },
	{ value: "zionsville", label: "Zionsville" },
];

export const ID_OFFICENAME = [
	{ value: "606b10f2f7ff2fc987137673", label: "COhatch Beachwood" },
	{ value: "606af961262dad011aa7c154", label: "COhatch Broad Ripple" },
	{ value: "61a4e2f49b30e7104f11278b", label: "COhatch Carmel" },
	{ value: "6109728eb12e145cca3b1f59", label: "COhatch Circle Centre" },
	{ value: "606af88d5e43340c60c209b8", label: "COhatch Delaware" },
	{ value: "606b0d3422b1003de911eea7", label: "COhatch Dublin" },
	{ value: "606af89d5e433411eec209cf", label: "COhatch Easton" },
	{ value: "61a502e6b36e570a87ac582f", label: "COhatch Findlay Market" },
	{ value: "606af857c10359a5c9f3f6ed", label: "COhatch Hyde Park" },
	{ value: "60a1138d775d0f03eec14ee0", label: "COhatch Kenwood" },
	{ value: "61435fba63579c94b09276e5", label: "COhatch Lakeland" },
	{ value: "5d1bcda0dbd6e40010479eec", label: "COhatch Mason" },
	{ value: "606b0ea033fc9a7d5b53a1b7", label: "COhatch Milford" },
	{ value: "606af848e1fae5fe0aa1c2af", label: "COhatch Noblesville" },
	{ value: "5fd23f130ecc6d8a668543db", label: "COhatch Ohio City" },
	{ value: "606af8ab5e4334f28dc209e7", label: "COhatch Polaris" },
	{ value: "624f4116bd2861034d10d844", label: "COhatch Polk Stables" },
	{ value: "6287bdffe608c584f6faecb1", label: "COhatch Southside Works" },
	{ value: "606af872e1fae54e2da1c315", label: "COhatch Springfield" },
	{ value: "61435e994274f6b1e4d2bff8", label: "COhatch St. Petersburg" },
	{ value: "624f40390fd203233bb9b659", label: "COhatch Tarpon Springs" },
	{ value: "606b14899ff4771341cc9201", label: "COhatch The Gateway" },
	{ value: "606af8810b1f05378ad799e7", label: "COhatch Upper Arlington" },
	{ value: "6287bdc9fc1c6944183a059f", label: "COhatch Shadyside" },
	{ value: "6287bd60e608c53fd3fae8d3", label: "COhatch Waterfront" },
	{ value: "61435f4e2a9271b93fb57221", label: "COhatch West Tampa" },
	{ value: "625675b22cec9a850f913a58", label: "COhatch Westerville" },
	{
		value: "606af8e161a5fbfe224c3002",
		label: "COhatch Worthington - The Hardware Store",
	},
	{
		value: "606af8be5e43345791c20a01",
		label: "COhatch Worthington - The Library & The Madery",
	},
	{ value: "61097250b12e1486413b171a", label: "COhatch Zionsville" },
];

export const OFFICEID_NAME = {
	"5d1bcda0dbd6e40010479eec": "COhatch Mason",
	"5fd23f130ecc6d8a668543db": "COhatch Ohio City",
	"606af848e1fae5fe0aa1c2af": "COhatch Noblesville",
	"606af857c10359a5c9f3f6ed": "COhatch Hyde Park",
	"606af872e1fae54e2da1c315": "COhatch Springfield",
	"606af8810b1f05378ad799e7": "COhatch Upper Arlington",
	"606af88d5e43340c60c209b8": "COhatch Delaware",
	"606af89d5e433411eec209cf": "COhatch Easton",
	"606af8ab5e4334f28dc209e7": "COhatch Polaris",
	"606af8be5e43345791c20a01": "COhatch Worthington - The Library & The Madery",
	"606af8e161a5fbfe224c3002": "COhatch Worthington - The Hardware Store",
	"606af961262dad011aa7c154": "COhatch Broad Ripple",
	"606b0d3422b1003de911eea7": "COhatch Dublin",
	"606b0ea033fc9a7d5b53a1b7": "COhatch Milford",
	"606b10f2f7ff2fc987137673": "COhatch Beachwood",
	"606b14899ff4771341cc9201": "COhatch The Gateway",
	"60a1138d775d0f03eec14ee0": "COhatch Kenwood",
	"61097250b12e1486413b171a": "COhatch Zionsville",
	"6109728eb12e145cca3b1f59": "COhatch Circle Centre",
	"61435e994274f6b1e4d2bff8": "COhatch St. Petersburg",
	"61435f4e2a9271b93fb57221": "COhatch West Tampa",
	"61435fba63579c94b09276e5": "COhatch Lakeland",
	"61a4e2f49b30e7104f11278b": "COhatch Carmel",
	"61a502e6b36e570a87ac582f": "COhatch Findlay Market",
	"624f40390fd203233bb9b659": "COhatch Tarpon Springs",
	"624f4116bd2861034d10d844": "COhatch Polk Stables",
	"625675b22cec9a850f913a58": "COhatch Westerville",
	"6287bd60e608c53fd3fae8d3": "COhatch Waterfront",
	"6287bdc9fc1c6944183a059f": "COhatch Shadyside",
	"6287bdffe608c584f6faecb1": "COhatch Southside Works",
};

export const BLOCKED_PAGES = {
	SUPERADMIN: [],
	MASTER_APPROVAL: ["dashboard/technical-support", "dashboard/administrator"],
	MARKET_LEADER: [
		"dashboard/technical-support",
		"dashboard/community-managers",
		"dashboard/administrator",
	],
	COMMUNITY_MANAGER: ["dashboard/technical-support", "dashboard/administrator"],
	TECHNICAL_SUPPORT: [
		"dashboard/community-managers",
		"dashboard/administrator",
	],
	LEADERSHIP: [
		"dashboard/technical-support",
		"dashboard/community-managers",
		"dashboard/administrator",
	],
};

export const BUSINESS_CATEGORY = [
	{ value: "business_services", label: "Business Services" },
	{ value: "home_services", label: "Home + Personal Services" },
	{ value: "shops", label: "Shops" },
	{ value: "makers_and_artisans", label: "Makers + Artisans" },
	{ value: "restaurants", label: "Restaurants" },
	{ value: "community_resources", label: "Community Resources" },
	{ value: "business_resources", label: "Business Resources" },
	{ value: "chambers", label: "Chambers" },
	{ value: "non_profits", label: "Local Nonprofits" },
	{ value: "farmers_market", label: "Farmers + Food " },
];

export const PROPERTY_CATEGORY = [
	{ value: "beach_vacation", label: "Beach Vacation" },
	{ value: "family_homes", label: "Family Homes" },
	{ value: "cabins", label: "Cabins" },
	{ value: "unique_stay", label: "Unique Stay" },
];

export const PROPERTY_BATHROOM_AMENITIES = [
	{
		value: "bathroom-essentials",
		label: "Essentials (towels, soap, toilet paper)",
		icon: GiTowel,
	},
	{ value: "hair-dryer", label: "Hair Dryer" },
	{ value: "shampoo", label: "Shampoo", icon: TbBottle },
	{ value: "conditioner", label: "Conditioner", icon: TbBottle },
	{ value: "body-wash", label: "Body Wash", icon: TbBottle },
	{ value: "body-lotion", label: "Body Lotion", icon: TbBottle },
	{ value: "bathtub", label: "Bathtub", icon: FaBath },
	{ value: "shower", label: "Shower", icon: FaShower },
];

export const PROPERTY_BEDROOM_AMENITIES = [
	{ value: "bed-linens", label: "Bed Linens", icon: BiBlanket },
	{
		value: "blankets-pillows",
		label: "Extra blankets & pillows",
		icon: GiPillow,
	},
	{
		value: "clothing-storage",
		label: "Clothing storage (dresser, closet)",
		icon: BiCloset,
	},
	{ value: "iron", label: "Iron", icon: MdIron },
	{ value: "ironing-board", label: "Ironing Board", icon: MdIron },
	{ value: "hangers", label: "Hangers", icon: TbHanger },
	{ value: "washer", label: "Washer", icon: GiWashingMachine },
	{ value: "dryer", label: "Dryer", icon: MdLocalLaundryService },
	{
		value: "laundry-detergent",
		label: "Laundry Detergent",
		icon: GiSpiralBottle,
	},
];

export const PROPERTY_ENTERTAINMENT_AMENITIES = [
	{ value: "tv", label: "TV", icon: FaTv },
	{ value: "wifi", label: "Wifi", icon: FaWifi },
	{ value: "cable", label: "Cable)", icon: GiCableStayedBridge },
	{ value: "streaming-services", label: "Streaming Services", icon: TbApps },
	{ value: "ping-pong", label: "Ping Pong Table", icon: GiPingPongBat },
	{ value: "pool-table", label: "Pool Table", icon: GiPoolTableCorner },
	{ value: "game-console", label: "Game Console", icon: GiConsoleController },
	{ value: "bluetooth-speaker", label: "Bluetooth Speaker", icon: MdSpeaker },
	{ value: "dedicated-workspace", label: "Dedicated Workspace", icon: GiDesk },
];

export const PROPERTY_KITCHEN_AMENITIES = [
	{
		value: "refridgerator",
		label: "Refridgerator",
		icon: CgSmartHomeRefrigerator,
	},
	{ value: "microwave", label: "Microwave", icon: MdMicrowave },
	{
		value: "silverware",
		label: "Dishes & Silverware (Bowls, plates, cups, forks, etc.)",
		icon: FaUtensils,
	},
	{ value: "dishwasher", label: "Dishwasher", icon: CgSmartHomeWashMachine },
	{ value: "stove", label: "Stove", icon: GiGasStove },
	{ value: "oven", label: "Oven", icon: MdOutlineMicrowave },
	{ value: "coffee-maker", label: "Coffee Maker", icon: MdOutlineCoffeeMaker },
	{ value: "toaster", label: "Toaster", icon: GiToaster },
	{ value: "dining-table", label: "Dining Table", icon: MdDining },
	{
		value: "cooking-pots",
		label: "Cooking Basics (pots & pans, oil, salt & pepper)",
		icon: GiCookingPot,
	},
	{ value: "tea-kettle", label: "Tea Kettle", icon: GiTeapotLeaves },
];

export const PROPERTY_SAFETY_AMENITIES = [
	{ value: "smoke-alarm", label: "Smoke Alarm", icon: RiAlarmWarningFill },
	{
		value: "carbon-monoxide-alarm",
		label: "Carbon Monoxide Alarm",
		icon: RiAlarmWarningFill,
	},
	{
		value: "fire-extinguisher",
		label: "Fire Extinguisher",
		icon: FaFireExtinguisher,
	},
	{ value: "first-aid-kit", label: "First Aid Kit", icon: GiFirstAidKit },
	{ value: "security-cameras", label: "Security Cameras", icon: GiCctvCamera },
	{ value: "gate", label: "Gate", icon: GiGate },
];

export const PROPERTY_COOLING_AMENITIES = [
	{ value: "ac", label: "Central Air Conditioning", icon: GiThermometerCold },
	{ value: "heater", label: "Central Heating", icon: GiThermometerHot },
	{ value: "indoor-fireplace", label: "Indoor Fireplace", icon: GiFireplace },
	{ value: "ceiling-fan", label: "Ceiling fan", icon: FaFan },
];

export const PROPERTY_FAMILY_AMENITIES = [
	{
		value: "childrens-books-toys",
		label: "Children's Books & Toys",
		icon: TbBooks,
	},
	{ value: "crib", label: "Crib", MdCrib },
	{ value: "high-chair", label: "High Chair", icon: GiWoodenChair },
	{ value: "board-games", label: "Board Games", icon: FaBox },
];

export const PROPERTY_PARKING_AMENITIES = [
	{
		value: "premise-parking",
		label: "Free Parking on Premises",
		icon: FaParking,
	},
	{ value: "garage-parking", label: "Garage Parking", icon: GiHomeGarage },
	{
		value: "free-street-parking",
		label: "Free Street Parking",
		icon: FaParking,
	},
	{
		value: "paid-street-parking",
		label: "Paid Street Parking",
		icon: FaParking,
	},
	{ value: "ev-charger", label: "EV Charger", icon: MdElectricCar },
	{ value: "bus-van-parking", label: "Bus/Van Parking", icon: FaParking },
];

export const PROPERTY_OUTDOOR_AMENITIES = [
	{ value: "private-backyard", label: "Private Backyard", icon: GiWoodenDoor },
	{ value: "fenced-yard", label: "Fenced Yard", icon: MdFence },
	{ value: "private-entrance", label: "Private Entrance", icon: GiDoorway },
	{ value: "fire-pit", label: "Fire Pit", icon: IoMdBonfire },
	{ value: "outdoor-furniture", label: "Outdoor Furniture", icon: GiSofa },
	{ value: "bbq-grill", label: "BBQ Grill", icon: MdOutdoorGrill },
	{ value: "gas-grill", label: "Gas Grill", icon: MdOutdoorGrill },
	{ value: "beach-towels", label: "Beach Towels", icon: GiTowel },
	{ value: "beach-umbrellas", label: "Beach Umbrellas", icon: FaUmbrellaBeach },
	{ value: "beach-chairs", label: "Beach Chairs", icons: GrLounge },
	{ value: "private-pool", label: "Private Pool", icon: FaSwimmingPool },
	{ value: "shared-pool", label: "Shared Pool", icon: FaSwimmingPool },
	{ value: "private-hot-tub", label: "Private Hot Tub", icon: FaHotTub },
	{ value: "shared-hot-tub", label: "Shared Hot Tub", icon: FaHotTub },
	{ value: "beach-access", label: "Beach Access", icon: MdBeachAccess },
	{ value: "waterfront", label: "Waterfront", icon: FaWater },
	{ value: "patio", label: "Patio or Balcony", icon: MdBalcony },
	{ value: "mountain-view", label: "Mountain View", icon: GiMountains },
	{ value: "beach-view", label: "Beach View", icon: TbBeach },
	{ value: "lake-view", label: "Lake View", icon: MdWater },
	{ value: "outdoor-shower", label: "Outdoor Shower", icon: GiShower },
];

export const PROPERTY_ACCESSIBILITY_AMENITIES = [
	{ value: "elevator", label: "Elevator", icon: MdElevator },
	{
		value: "wheelchair-accessible",
		label: "Wheelchair Accessible",
		icon: FaWheelchair,
	},
	{
		value: "accessible-parking",
		label: "Accessible parking available",
		icon: FaWheelchair,
	},
];

export const PROPERTY_INFO_FEATURES = [
	{ value: "eventsAllowed", label: "Events Allowed", icon: GiPartyPopper },
	{ value: "petFriendly", label: "Pet Friendly", icon: MdPets },
	{ value: "childrenWelcome", label: "Children Welcome", icon: FaChild },
];

export const WORKSHOP_CATEGORY = [
	{ value: "business_professional", label: "Business and Professional" },
	{ value: "community_culture", label: "Community and Culture" },
	{ value: "drives_donations", label: "Drives + Donations" },
	{ value: "family_education", label: "Family and Education" },
	{ value: "fashion_beauty", label: "Fashion and Beauty" },
	{
		value: "film_media_entertainment",
		label: "Film, Media, and Entertainment",
	},
	{ value: "food_drink", label: "Food and Drink" },
	{ value: "fundraising", label: "Fundraising" },
	{ value: "government_politics", label: "Government and Politics" },
	{ value: "health_wellness", label: "Health and Wellness" },
	{ value: "hobbies", label: "Hobbies and Special Interest" },
	{ value: "home_lifestyle", label: "Home and Lifestyle" },
	{ value: "music", label: "Music" },
	{ value: "performing", label: "Performing and Visual Arts" },
	{ value: "religion_spirituality", label: "Religion and Spirituality" },
	{ value: "school_activities", label: "School Activities" },
	{ value: "science_technology", label: "Science and Technology" },
	{ value: "Seasonal_holidayk", label: "Seasonal and Holiday" },
	{ value: "sports_fitness", label: "Sports and Fitness" },
	{ value: "travel_outdoor", label: "Travel and Outdoor" },
];
