/* eslint-disable */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useLogout from "./../../hooks/useLogout";
import { capitalizeFirstLetter } from "../../services/util";
import Logo from "./../../components/logo/Logo";
import { MdSupport } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { SiSemanticscholar } from "react-icons/si";
import { FaChartLine } from "react-icons/fa6";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function DropdownMenuItem({ to, children, location, loggingOut }) {
	return (
		<NavLink
			to={loggingOut ? "" : to}
			end
			className={() => {
				const isActive = location.pathname.indexOf(to) > -1;
				return isActive
					? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
					: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
			}}
		>
			{children}
		</NavLink>
	);
}

function DropdownMenu({ label, children, location, loggingOut }) {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div>
			<div
				className="text-white hover:bg-primary-400 group flex items-center justify-between px-2 py-2 text-sm rounded-md transition-all duration-500 cursor-pointer"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span>{label}</span>
				<svg
					className={classNames(
						isOpen ? "rotate-90 text-gray-400" : "text-gray-300",
						"ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
					)}
					viewBox="0 0 20 20"
					aria-hidden="true"
				>
					<path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
				</svg>
			</div>
			{isOpen && (
				<div className="space-y-1 ml-6">
					{children}
				</div>
			)}
		</div>
	);
}

function DashboardNavigation({ isFullScreen, setIsFullScreen }) {
	const [loggingOut, setLoggingOut] = React.useState(false);
	const [handleLogout] = useLogout(setLoggingOut);
	const location = useLocation();
	const userState = {
		market: "market", // Set a default value for demonstration
	};
	const [tooltip, setTooltip] = useState({ visible: false, text: "", position: { top: 0, left: 0 } });

	const showTooltip = (text, event) => {
		const { clientX: left, clientY: top } = event;
		setTooltip({ visible: true, text, position: { top: top + 15, left } });
	};

	const hideTooltip = () => {
		setTooltip({ visible: false, text: "", position: { top: 0, left: 0 } });
	};

	return (
		<>
			<nav className="flex-1 px-2 pb-4 space-y-1 relative">
				{isFullScreen && (
					<div className="flex items-center px-2 py-2 text-sm mb-2">
							<span className="material-icons-outlined text-white">
								keyboard_double_arrow_right
							</span>
					</div>
				)}

				<NavLink
					to={loggingOut ? "" : "/dashboard"}
					end
					className={({ isActive }) => {
						return isActive
							? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
							: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
					}}
					onMouseEnter={(e) => isFullScreen && showTooltip("Admin Dashboard", e)}
					onMouseLeave={hideTooltip}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
						home
					</span>
					{!isFullScreen && "Admin Dashboard"}
				</NavLink>

				<NavLink
					to={loggingOut ? "" : "/dashboard/community-managers"}
					end
					className={() => {
						const isActive = location.pathname.indexOf("/dashboard/community-managers") > -1;
						return isActive
							? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
							: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
					}}
					onMouseEnter={(e) => isFullScreen && showTooltip("Community Managers", e)}
					onMouseLeave={hideTooltip}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
						location_city
					</span>
					{!isFullScreen && "Community Managers"}
				</NavLink>

				<NavLink
					to={loggingOut ? "" : "/dashboard/administrator"}
					end
					className={() => {
						const isActive = location.pathname.indexOf("/dashboard/administrator") > -1;
						return isActive
							? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
							: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
					}}
					onMouseEnter={(e) => isFullScreen && showTooltip("Administrators", e)}
					onMouseLeave={hideTooltip}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
						admin_panel_settings
					</span>
					{!isFullScreen && "Administrators"}
				</NavLink>

				{!isFullScreen && (
					<DropdownMenu
						label={
							<>
								<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
									dashboard
								</span>
								Impact
							</>
						}
						location={location}
						loggingOut={loggingOut}
					>
						{/* <DropdownMenuItem
							to="/dashboard/impact"
							location={location}
							loggingOut={loggingOut}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								description
							</span>
							{!isFullScreen && "Impact Dashboard"}
						</DropdownMenuItem> */}
						<DropdownMenuItem
							to="/dashboard/locations"
							location={location}
							loggingOut={loggingOut}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								layers
							</span>
							{!isFullScreen && "All Locations"}
						</DropdownMenuItem>
						<DropdownMenuItem
							to={`/dashboard/marketlevel/${userState.market}`}
							location={location}
							loggingOut={loggingOut}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								query_stats
							</span>
							{!isFullScreen &&
								(userState.market
									? `${capitalizeFirstLetter(userState.market)}`
									: "Market Level")}
						</DropdownMenuItem>
						<DropdownMenuItem
							to="/dashboard/leaderboard"
							location={location}
							loggingOut={loggingOut}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								analytics
							</span>
							{!isFullScreen && "Leaderboard"}
						</DropdownMenuItem>
						{/* <DropdownMenuItem
							to="/dashboard/reports"
							location={location}
							loggingOut={loggingOut}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								summarize
							</span>
							{!isFullScreen && "Impact Reports"}
						</DropdownMenuItem> */}
						<DropdownMenuItem
							to="/dashboard/all_applications"
							location={location}
							loggingOut={loggingOut}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								person_search
							</span>
							{!isFullScreen && "All Applications"}
						</DropdownMenuItem>
					</DropdownMenu>
				)}

				<NavLink
					to={loggingOut ? "" : "/dashboard/technical-support"}
					end
					className={() => {
						const isActive = location.pathname.indexOf("/dashboard/technical-support") > -1;
						return isActive
							? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
							: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
					}}
					onMouseEnter={(e) => isFullScreen && showTooltip("Technical Support", e)}
					onMouseLeave={hideTooltip}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
						speed
					</span>
					{!isFullScreen && "Technical Support"}
				</NavLink>

				<NavLink
					to={loggingOut ? "" : "/dashboard/settings"}
					end
					className={({ isActive }) => {
						return isActive
							? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
							: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
					}}
					onMouseEnter={(e) => isFullScreen && showTooltip("Settings", e)}
					onMouseLeave={hideTooltip}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
						settings
					</span>
					{!isFullScreen && "Settings"}
				</NavLink>

				{isFullScreen && (
					<>
						{/* <NavLink
							to={loggingOut ? "" : "/dashboard/impact"}
							end
							className={() => {
								const isActive = location.pathname.indexOf("/impact") > -1;
								return isActive
									? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
									: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
							}}
							onMouseEnter={(e) => showTooltip("Impact Dashboard", e)}
							onMouseLeave={hideTooltip}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								description
							</span>
							{!isFullScreen && "Impact Dashboard"}
						</NavLink> */}

						<NavLink
							to={loggingOut ? "" : "/dashboard/locations"}
							end
							className={() => {
								const isActive = location.pathname.indexOf("/dashboard/locations") > -1;
								return isActive
									? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
									: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
							}}
							onMouseEnter={(e) => showTooltip("All Locations", e)}
							onMouseLeave={hideTooltip}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								layers
							</span>
							{!isFullScreen && "All Locations"}
						</NavLink>

						<NavLink
							to={loggingOut ? "" : `/dashboard/marketlevel/${userState.market}`}
							end
							className={() => {
								const isActive = location.pathname.indexOf(`/dashboard/marketlevel/${userState.market}`) > -1;
								return isActive
									? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500"
									: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm rounded-md transition-all duration-500";
							}}
							onMouseEnter={(e) => showTooltip(userState.market ? `${capitalizeFirstLetter(userState.market)}` : "Market Level", e)}
							onMouseLeave={hideTooltip}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
								query_stats
							</span>
							{!isFullScreen &&
								(userState.market
									? `${capitalizeFirstLetter(userState.market)}`
									: "Market Level")}
						</NavLink>

						<NavLink
							to={loggingOut ? "" : "/dashboard/leaderboard"}
							className={() => {
								const isActive = location.pathname.indexOf("/applications") > -1;
								return isActive
									? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
									: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
							}}
							onMouseEnter={(e) => showTooltip("Leaderboard", e)}
							onMouseLeave={hideTooltip}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 ">
								analytics
							</span>
							{!isFullScreen && "Leaderboard"}
						</NavLink>

						{/* <NavLink
							to={loggingOut ? "" : "/dashboard/reports"}
							className={() => {
								const isActive = location.pathname.indexOf("/dashboard/reports") > -1;
								return isActive
									? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
									: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
							}}
							onMouseEnter={(e) => showTooltip("Impact Reports", e)}
							onMouseLeave={hideTooltip}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 ">
								summarize
							</span>
							{!isFullScreen && "Impact Reports"}
						</NavLink> */}

						<NavLink
							to={loggingOut ? "" : "/dashboard/all_applications"}
							className={() => {
								const isActive = location.pathname.indexOf("/applications") > -1;
								return isActive
									? "bg-primary-400 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
									: "text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500";
							}}
							onMouseEnter={(e) => showTooltip("All Applications", e)}
							onMouseLeave={hideTooltip}
						>
							<span className="mr-3 material-icons-outlined h-6 w-6 ">
								person_search
							</span>
							{!isFullScreen && "All Applications"}
						</NavLink>
					</>
				)}
				<div
					className="cursor-pointer text-white hover:bg-primary-400 group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-500"
					onClick={handleLogout}
					onMouseEnter={(e) => isFullScreen && showTooltip("Logout", e)}
					onMouseLeave={hideTooltip}
				>
					<span className="mr-3 material-icons-outlined h-6 w-6 text-white">
						logout
					</span>
					{!isFullScreen && "Logout"}
				</div>
			</nav>
			{tooltip.visible && (
				<div
					id="tooltip-right"
					role="tooltip"
					className="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-100"
					style={{ top: tooltip.position.top, left: tooltip.position.left }}
				>
					{tooltip.text}
					<div className="tooltip-arrow" data-popper-arrow></div>
				</div>
			)}
		</>
	);
}

export default DashboardNavigation;
