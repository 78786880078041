/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
/* eslint-disable react/prop-types */
import React, { useState, Fragment } from "react";
import useUserContext from "../../../hooks/useUserContext";
import { CgFontSpacing } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import Modal from "../../../components/modal/Modal";
import { MAGIC_NUMBERS } from "../../../constant";
import notify from "../../../services/toast";
import sendCpInvite from "./sendCpInvite";
import CommunityPartnerForm from "../../community_partner/CommunityPartnerForm";

export const Total = ({ marketData, type, dashstats }) => {
	const { userState } = useUserContext();
	const award = dashstats.annualAward;
	const giveAward = dashstats.annualGiveAward;
	const boostAward = dashstats.annualBoostAward;
	const cpAward = dashstats.annualCPAward;
	const navigate = useNavigate();
	const renewal = dashstats.totalRenewals;
	const nonRenewal = dashstats.totalNonRenewals;
	const giveRenewal = dashstats.totalGiveRenewals;
	const giveNonRenewal = dashstats.totalGiveNonRenewals;
	const boostRenewal = dashstats.totalBoostRenewals;
	const boostNonRenewal = dashstats.totalBoostNonRenewals;
	const [inviteCP, setInviteCP] = useState(false);
	const [cpEmail, setCPEmail] = useState("");
	const [showCPForm, setShowCPForm] = useState(false);
	const [isSending, setIsSending] = useState(false);
	//Pie graph
	const data = [
		{ name: "Renewing Scholars", value: renewal, color: "#ffbb28" },
		{ name: "New Scholars", value: nonRenewal, color: "#00C49F" },
	];

	const dataGive = [
		{ name: "Renewing Scholars", value: giveRenewal, color: "#ffbb28" },
		{ name: "New Scholars", value: giveNonRenewal, color: "#00C49F" },
	];
	const dataBoost = [
		{ name: "Renewing Scholars", value: boostRenewal, color: "#ffbb28" },
		{ name: "New Scholars", value: boostNonRenewal, color: "#00C49F" },
	];

	function CustomLabel({ viewBox, value1, value2 }) {
		const { cx, cy } = viewBox;
		return (
			<svg
				// width="500"
				// height="200"
				className="recharts-text recharts-label"
				textAnchor="middle"
				dominantBaseline="central"
			>
				<text x={cx} y={cy} fill="#3d405c">
					<tspan x={cx} dy="0em" alignmentBaseline="middle" fontSize="14">
						{`${value1} Renewing `}
					</tspan>
					<tspan x={cx} dy="1em" fontSize="14">
						{`${value2} New`}
					</tspan>
				</text>
			</svg>
			//  <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
			//     <tspan alignmentBaseline="middle" fontSize="26">{value1}</tspan>
			//     <tspan fontSize="14">{value2}</tspan>
			//  </text>
		);
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		const emailAddress = { email: cpEmail };
		setIsSending(true); // Disable the button while sending
		try {
			const response = await sendCpInvite(emailAddress);
			setIsSending(false); // Re-enable the button after sending

			if (response.status === "success") {
				notify("Invite sent successfully!", "success");
			} else {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists, please contact support.";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setIsSending(false); // Re-enable the button after catching an error
			notify("Unable to send Invite. Please try again later.", "error");
		}
	};
	if (!type) {
		return (
			<>
				<section className="grid grid-cols-1 md:grid-cols-5 mt-4 gap-6 mb-8">
					{/* Stats Cards */}
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 col-span-5 md:col-span-3">
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-800">
								${award?.[0]?.annualAward?.toLocaleString() || 0}
							</h2>
							<p className="text-gray-600">Scholarship Dollars Awarded</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-600">
								{dashstats.totalApprovedApplications}
							</h2>
							<p className="text-gray-600">Active Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-yellow-600">
								{dashstats.totalPendingApplications}
							</h2>
							<p className="text-gray-600">Pending Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-red-600">
								{dashstats.totalDeniedApplications}
							</h2>
							<p className="text-gray-600">Denied Scholars</p>
						</div>
					</div>

					{/* Pie Chart */}
					<div className="p-6 bg-white rounded-lg shadow-md flex justify-center items-center col-span-5 md:col-span-2">
						<h2 className="text-2xl font-semibold text-gray-800 mb-4 w-full text-center">
							Renewal VS. New Scholars
						</h2>
						<PieChart width={250} height={250}>
							<Pie
								data={data}
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={100}
								dataKey="value"
							>
								<Label content={<CustomLabel value1={renewal} value2={nonRenewal} />} />
								{data.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={entry.color} />
								))}
							</Pie>
							<Tooltip />
						</PieChart>
					</div>
				</section>



				<div className="inline-block min-w-full py-2 align-middle">
					<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
						<table className="min-w-full divide-y divide-gray-300 text-sm md:text-base">
							<thead className="bg-success-800 text-white">
								<tr>
									<th
										scope="col"
										className="px-6 py-3 text-left font-semibold uppercase tracking-wider text-xs sm:text-sm md:text-base"
									>
										City
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left font-semibold uppercase tracking-wider text-xs sm:text-sm md:text-base"
									>
										Total
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left font-semibold uppercase tracking-wider text-xs sm:text-sm md:text-base"
									>
										Pending
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left font-semibold uppercase tracking-wider text-xs sm:text-sm md:text-base"
									>
										Approved
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left font-semibold uppercase tracking-wider text-xs sm:text-sm md:text-base"
									>
										Denied
									</th>
								</tr>
							</thead>

							<tbody className="divide-y divide-gray-200 bg-white">
								{[
									{ city: "Atlanta", total: marketData.totalAtlanta, pending: marketData.totalPendingAtlanta, approved: marketData.totalApprovedAtlanta, denied: marketData.totalDeniedAtlanta },
									{ city: "Charlotte", total: marketData.totalCharlotte, pending: marketData.totalPendingCharlotte, approved: marketData.totalApprovedCharlotte, denied: marketData.totalDeniedCharlotte },
									{ city: "Cincinnati", total: marketData.totalCincinnati, pending: marketData.totalPendingCincinnati, approved: marketData.totalApprovedCincinnati, denied: marketData.totalDeniedCincinnati },
									{ city: "Cleveland", total: marketData.totalCleveland, pending: marketData.totalPendingCleveland, approved: marketData.totalApprovedCleveland, denied: marketData.totalDeniedCleveland },
									{ city: "Columbus", total: marketData.totalColumbus, pending: marketData.totalPendingColumbus, approved: marketData.totalApprovedColumbus, denied: marketData.totalDeniedColumbus },
									{ city: "Indianapolis", total: marketData.totalIndianapolis, pending: marketData.totalPendingIndianapolis, approved: marketData.totalApprovedIndianapolis, denied: marketData.totalDeniedIndianapolis },
									{ city: "Pittsburgh", total: marketData.totalPittsburgh, pending: marketData.totalPendingPittsburgh, approved: marketData.totalApprovedPittsburgh, denied: marketData.totalDeniedPittsburgh },
									{ city: "Springfield", total: marketData.totalSpringfield, pending: marketData.totalPendingSpringfield, approved: marketData.totalApprovedSpringfield, denied: marketData.totalDeniedSpringfield },
									{ city: "Central Florida", total: marketData.totalTampa, pending: marketData.totalPendingTampa, approved: marketData.totalApprovedTampa, denied: marketData.totalDeniedTampa },
								].map((row, index) => (
									<tr
										key={index}
										className="cursor-pointer hover:bg-indigo-50 transition duration-200 ease-in-out"
										onClick={() => navigate(`/dashboard/locations/${row.city.toLowerCase().replace(" ", "_")}`)}
									>
										<td className="whitespace-nowrap py-4 pl-6 font-medium text-gray-900">{row.city}</td>
										<td className="text-md px-6 py-4 text-gray-900">{row.total}</td>
										<td className="text-md px-6 py-4 text-yellow-500">{row.pending}</td>
										<td className="text-md px-6 py-4 text-green-500">{row.approved}</td>
										<td className="text-md px-6 py-4 text-red-500">{row.denied}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	} else if (type === "give") {
		return (
			<>
				<section className="grid grid-cols-1 md:grid-cols-5 mt-4 gap-6 mb-8">
					{/* Stats Cards */}
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 col-span-5 md:col-span-3">
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-800">
								${giveAward?.[0]?.annualAward ? giveAward[0].annualAward.toLocaleString() : 0}
							</h2>
							<p className="text-gray-600">Scholarship Dollars Awarded</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-600">
								{dashstats.totalApprovedGiveApplications}
							</h2>
							<p className="text-gray-600">Active Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-yellow-600">
								{dashstats.totalPendingGiveApplications}
							</h2>
							<p className="text-gray-600">Pending Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-red-600">
								{dashstats.totalDeniedGiveApplications}
							</h2>
							<p className="text-gray-600">Denied Scholars</p>
						</div>
					</div>

					{/* Pie Chart */}
					<div className="p-6 bg-white rounded-lg shadow-md flex justify-center items-center col-span-5 md:col-span-2">
						<h2 className="text-2xl font-semibold text-gray-800 mb-4 w-full text-center">
							Renewal VS. New Scholars
						</h2>
						<PieChart width={250} height={250}>
							<Pie
								data={data}
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={100}
								dataKey="value"
							>
								<Label
									content={
										<CustomLabel value1={giveRenewal} value2={boostRenewal} />
									}
								></Label>
								{data.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={entry.color} />
								))}
							</Pie>
							<Tooltip />
						</PieChart>
					</div>
				</section>

				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedCharlotte}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedCincinnati}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedCleveland}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedColumbus}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedIndianapolis}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedPittsburgh}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Central Florida
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGivePendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (type === "boost") {
		return (
			<>
				<section className="grid grid-cols-1 md:grid-cols-5 mt-4 gap-6 mb-8">
					{/* Stats Cards */}
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 col-span-5 md:col-span-3">
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-800">
								${boostAward?.[0]?.annualAward ? boostAward[0].annualAward.toLocaleString() : 0}
							</h2>
							<p className="text-gray-600">Scholarship Dollars Awarded</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-600">
								{dashstats.totalApprovedBoostApplications}
							</h2>
							<p className="text-gray-600">Active Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-yellow-600">
								{dashstats.totalPendingBoostApplications}
							</h2>
							<p className="text-gray-600">Pending Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-red-600">
								{dashstats.totalDeniedBoostApplications}
							</h2>
							<p className="text-gray-600">Denied Scholars</p>
						</div>
					</div>

					{/* Pie Chart */}
					<div className="p-6 bg-white rounded-lg shadow-md flex justify-center items-center col-span-5 md:col-span-2">
						<h2 className="text-2xl font-semibold text-gray-800 mb-4 w-full text-center">
							Renewal VS. New Scholars
						</h2>
						<PieChart width={250} height={250}>
							<Pie
								data={data}
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={100}
								dataKey="value"
							>
								<Label
									content={
										<CustomLabel
											value1={boostRenewal}
											value2={boostNonRenewal}
										/>
									}
								></Label>
								{data.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={entry.color} />
								))}
							</Pie>
							<Tooltip />
						</PieChart>
					</div>
				</section>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCharlotte}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCincinnati}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCleveland}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedColumbus}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedIndianapolis}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedPittsburgh}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Central Florida
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else if (type === "Community Partner") {
		return (
			<>
				<div className="grid grid-cols-1 md:grid-cols-6 mt-4 gap-6 mb-8">
					{/* Left Section: Widget Cards */}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-4">
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-800">
								$
								{cpAward?.[0]?.annualAward
									? cpAward[0].annualAward.toLocaleString()
									: 0}
							</h2>
							<p className="text-gray-600">Scholarship Dollars Awarded</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-success-600">
								{dashstats.totalApprovedCPApplications}
							</h2>
							<p className="text-gray-600">Active Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-yellow-600">
								{dashstats.totalPendingCPApplications}
							</h2>
							<p className="text-gray-600">Pending Scholars</p>
						</div>
						<div className="p-4 bg-white rounded-lg shadow-md">
							<h2 className="text-4xl font-bold text-red-600">
								{dashstats.totalDeniedCPApplications}
							</h2>
							<p className="text-gray-600">Denied Scholars</p>
						</div>
					</div>

					{/* Right Section: Add New Community Partner */}
					<div className="p-6 bg-white rounded-lg shadow-md col-span-2 flex justify-center items-center">
						<div className="w-full max-w-md">
							<h2 className="font-bold text-2xl mb-4 text-center">
								Add New Community Partner
							</h2>
							<button
								onClick={() => setInviteCP(true)}
								className="w-full rounded-md bg-indigo-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Actions
							</button>
						</div>
					</div>
				</div>

				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="mb-3  shadow ring-1 ring-black ring-opacity-5">
										<tr>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												City
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Total
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Pending
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold"
											>
												Approved
											</th>
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold "
											>
												Denied
											</th>
										</tr>
									</thead>

									<tbody className="divide-y divide-gray-200 bg-white">
										<tr
											onClick={() => {
												navigate("/dashboard/locations/charlotte");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Charlotte
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPPendingCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPApprovedCharlotte}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPDeniedCharlotte}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cincinnati");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cincinnati
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPPendingCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPApprovedCincinnati}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPDeniedCincinnati}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/cleveland");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Cleveland
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedCleveland}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedCleveland}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/columbus");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Columbus
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedColumbus}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedColumbus}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/indianapolis");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Indianapolis
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedIndianapolis}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalGiveDeniedIndianapolis}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/pittsburgh");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Pittsburgh
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostPendingPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostApprovedPittsburgh}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalBoostDeniedPittsburgh}
											</td>
										</tr>
										<tr
											onClick={() => {
												navigate("/dashboard/locations/tampa_bay");
											}}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
												Central Florida
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPPendingTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPApprovedTampa}
											</td>
											<td className="pl-5 text-md text-gray-900">
												{marketData.totalCPDeniedTampa}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{inviteCP && (
					<Modal>
						<div className="fixed z-10 inset-0 overflow-y-auto">
							<div className="flex items-center justify-center min-h-screen p-4">
								<div className="relative inline-block align-bottom bg-white rounded-lg shadow-xl transition-transform transform sm:max-w-xl md:max-w-2xl lg:max-w-3xl w-full p-8 space-y-6">
									{!showCPForm && (
										<>
											<button
												className="absolute top-4 left-4 text-gray-600 hover:text-gray-800 transition duration-300"
												onClick={() => setInviteCP(false)}
											>
												<span className="material-icons-outlined text-2xl">arrow_back_ios</span>
											</button>
											<h1 className="text-2xl font-bold text-center">Invite a Community Partner</h1>
											<p className="text-gray-700 text-center mb-4">
												Please enter the email of the Community Partner you would like to send an invite to.
												After they receive the email, they will be prompted to submit their information.
											</p>
											<form onSubmit={onSubmit} noValidate className="w-full">
												<div className="flex flex-col sm:flex-row gap-4">
													<input
														id="email-address"
														name="email"
														type="email"
														autoComplete="email"
														required
														className="flex-grow rounded-md border-gray-300 shadow-sm focus:ring-2 focus:ring-indigo-600 placeholder:text-gray-400 sm:py-2 sm:px-3"
														placeholder="Enter email"
														onChange={(event) => setCPEmail(event.target.value)}
													/>
													<button
														type="submit"
														className="flex-none rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-md hover:bg-indigo-500 transition duration-300"
														disabled={isSending}
													>
														{isSending ? "Sending..." : "Send"}
													</button>
												</div>
											</form>

											<div className="mt-6 text-center">
												<h2 className="text-lg font-bold">Create Profile</h2>
												<p className="text-gray-600 mb-4">
													Fill in the Community Partner's details.
												</p>
												<button
													onClick={() => setShowCPForm(true)}
													className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-md hover:bg-indigo-500 transition duration-300"
												>
													Community Partner Form{" "}
													<span className="material-icons-outlined text-xs font-bold">arrow_forward_ios</span>
												</button>
											</div>
										</>
									)}
									{showCPForm && (
										<div className="py-6 px-4 bg-gray-100 rounded-lg shadow-lg relative">
											<button
												className="absolute top-4 left-4 flex items-center text-gray-600 hover:text-gray-800 transition duration-300"
												onClick={() => setShowCPForm(false)}
												aria-label="Go back"
											>
												<span className="material-icons-outlined text-4xl mr-2">arrow_back</span>
												Go Back
											</button>
											<CommunityPartnerForm />
										</div>
									)}

								</div>
							</div>
						</div>
					</Modal>
				)}

			</>
		);
	}
};
