/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal from "../../components/modal/Modal";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import { MAGIC_NUMBERS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import notify from "../../services/toast";
import { debounce } from "../../services/util";
import { Box } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton } from "@mui/x-data-grid";
import { MdLocationPin } from "react-icons/md";
import { format } from "date-fns";
import deleteApplication from "./requests/deleteApplication";
import getApplicants from "./requests/getApplicantsSearch";

function ScholarshipApplications() {
	const { userState } = useUserContext();

	const [keyword, setKeyword] = useState("");
	const [marketData, setMarketData] = useState([]);
	const [pagination, setPagination] = useState({
		total: 0,
		page: 0,
		limit: 15,
	});
	const [isApplicantsLoading, setIsApplicantsLoading] = useState(false);
	const [currentId, setCurrentId] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	function getRowId(row) {
		return row._id;
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarExport />
			</GridToolbarContainer>
		);
	}

	const fetchApplicants = async (accessToken, page, keyword) => {
		setIsApplicantsLoading(true);
		const response = await getApplicants(
			{
				page,
				keyword,
				limit: pagination.limit,
			},
			accessToken
		);
	
		if (response.status === "success") {
			const { total, page, results } = response.data;
	
			// Sort the results by the "createdAt" field (newest to oldest)
			const sortedResults = results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
	
			setPagination((prev) => ({ ...prev, total, page }));
			setMarketData(sortedResults);
		} else {
			notify("Error fetching applicants", "error");
		}
		setIsApplicantsLoading(false);
	};
	

	const handleSearch = (event) => {
		const query = event.target.value;
		fetchApplicants(userState.accessToken, 0, query);
		setKeyword(query);
	};

	const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

	useEffect(() => {
		fetchApplicants(userState.accessToken, 0, "");
	}, []);

	const handlePageChange = (newPage) => {
		fetchApplicants(userState.accessToken, newPage, keyword);
	};

	const confirmDelete = (id) => {
		setCurrentId(id);
		setIsDeleteModalOpen(true);
	};

	const handleDelete = async () => {
		if (currentId) {
			const response = await deleteApplication(currentId, userState.accessToken);
			if (response.status === "success") {
				notify("Application successfully deleted", "info");
				fetchApplicants(userState.accessToken, pagination.page, keyword);
			} else {
				notify("Failed to delete application", "error");
			}
		}
		setIsDeleteModalOpen(false);
		setCurrentId(null);
	};

	const columns = [
		{
			field: "orgName",
			headerName: "Organization",
			flex: 2,
			renderCell: ({ row: { logoImage, orgName, _id } }) => (
				<a href={`/dashboard/profile/${_id}`} target="_blank" rel="noopener noreferrer">
					<div className="flex items-center">
						{logoImage?.small?.url ? (
							<img
								className="h-12 w-12 rounded-full"
								src={logoImage.small.url}
								alt={orgName}
							/>
						) : logoImage?.logoImage?.url ? (
							<img
								className="h-12 w-12 rounded-full"
								src={logoImage.logoImage.url}
								alt={orgName}
							/>
						) : (
							<div className="h-12 w-12 rounded-full bg-gray-300 flex items-center justify-center">
								<span className="text-gray-500">N/A</span>
							</div>
						)}
						<div className="ml-3">
							<h1 className="font-bold">{orgName}</h1>
						</div>
					</div>
				</a>
			),
		},
		{
			field: "type",
			headerName: "Scholarship Type",
			flex: 1,
			renderCell: ({ row: { type } }) => <span className="capitalize">{type}</span>,
		},
		{
			field: "appStatus",
			headerName: "App Status",
			flex: 1,
			renderCell: ({ row: { appStatus } }) => (
				<span
					className={`capitalize font-bold ${appStatus === "approved"
							? "text-green-500"
							: appStatus === "rejected"
								? "text-red-500"
								: "text-gray-500"
						}`}
				>
					{appStatus}
				</span>
			),
		},
		{
			field: "cmStatus",
			headerName: "CM Status",
			flex: 1,
			renderCell: ({ row: { cmStatus } }) => (
				<span
					className={`capitalize font-bold ${cmStatus === "approved"
							? "text-blue-500"
							: cmStatus === "rejected"
								? "text-red-500"
								: "text-gray-500"
						}`}
				>
					{cmStatus}
				</span>
			),
		},
		{
			field: "createdAt",
			headerName: "Submission Date",
			flex: 1,
			valueGetter: ({ value }) => value && format(new Date(value), "MM/dd/yyyy"),
		},
		{
			field: "coLocation",
			headerName: "Location",
			flex: 1,
			renderCell: ({ row: { coLocation, _id } }) => (
				<span className="flex items-center">
					<MdLocationPin className="text-xl mr-1" /> {coLocation || "Unknown"}
				</span>
			),
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			renderCell: ({ row: { _id } }) => (
				<button
					onClick={() => confirmDelete(_id)}
					className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-700"
				>
					Delete
				</button>
			),
		},
	];

	return (
		<div className="mt-5 mx-auto bg-white rounded-md shadow-md p-4">
			<h1 className="text-2xl font-bold text-gray-900">Scholarship Applications</h1>
			<div className="flex mt-4">
				<input
					type="text"
					placeholder="Search Applicants"
					className="border border-gray-400 rounded-md p-2 w-full"
					onInput={debouncedHandleSearch}
				/>
			</div>
			<Box mt={4} height="60vh">
				<DataGrid
					rows={marketData}
					columns={columns}
					rowHeight={80}
					getRowId={getRowId}
					loading={isApplicantsLoading}
					components={{ Toolbar: CustomToolbar }}
					pagination
					pageSize={pagination.limit}
					paginationMode="server"
					onPageChange={(newPage) => handlePageChange(newPage)}
					hideFooterPagination={true} // Hides the "Rows per page" dropdown
				/>
			</Box>
			<div className="mt-4 flex justify-between">
				<button
					disabled={pagination.page === 0}
					onClick={() => handlePageChange(pagination.page - 1)}
					className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
				>
					Previous
				</button>
				<span className="text-gray-600">
					Page {pagination.page + 1} of {Math.ceil(pagination.total / pagination.limit)}
				</span>
				<button
					disabled={(pagination.page + 1) * pagination.limit >= pagination.total}
					onClick={() => handlePageChange(pagination.page + 1)}
					className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
				>
					Next
				</button>
			</div>

			{/* Delete Confirmation Modal */}
			{isDeleteModalOpen && (
				<Modal>
					<div className="p-6 bg-white">
						<h2 className="text-lg font-bold ">Confirm Deletion</h2>
						<p className="mt-2">Are you sure you want to delete this application? This action cannot be undone.</p>
						<div className="mt-4 flex justify-end">
							<button
								className="px-4 py-2 bg-gray-300 rounded-md mr-2"
								onClick={() => setIsDeleteModalOpen(false)}
							>
								Cancel
							</button>
							<button
								className="px-4 py-2 bg-red-500 text-white rounded-md"
								onClick={handleDelete}
							>
								Confirm
							</button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default ScholarshipApplications;
