/* eslint-disable */
import React, { useState, useEffect } from "react";
import { format, addYears } from "date-fns";
import SelectInput from "../../components/select_input/SelectInput";
import { v4 as uuid } from "uuid";
import { APP_LOCATIONS, COHATCH_MARKETS } from "../../constant";
import useUserContext from "../../hooks/useUserContext";
import { useNavigate } from "react-router-dom";
import { inArray } from "../../services/util";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function EditApplicationModal({ handleSubmit, open, onClose, app }) {
	const { userState } = useUserContext();
	const navigate = useNavigate();
	const [market, setMarket] = useState();

	const [scoreMemAwarded, setScoreMemAwarded] = useState();

	const [awardedMember, setAwardedMember] = useState();
	const createdAt = app?.createdAt ? app.createdAt : null;
	const comarket = "" + app.market;
	const location = "" + app.coLocation;
	const Market = comarket.toUpperCase();
	const Colocation = location.toUpperCase();

	function addYears(date, years) {
		date.setFullYear(date.getFullYear() + years);
		return date;
	}

	var submissionDate = new Date(createdAt);
	var newDate = addYears(submissionDate, 1);

	// Updating Application
	const [payload, setPayload] = React.useState({
		firstName: app.firstName,
		lastName: app.lastName,
		email: app.email,
		phoneNumber: app.phoneNumber,
		cmStatus: app.cmStatus,
		mlStatus: app.mlStatus, // Corrected typo from "mlStauts" to "mlStatus"
		appStatus: app.appStatus,
		officeRnd: app.officeRnd,
		scoreMission: app.scoreMission,
		scoreImpact: app.scoreImpact,
		scoreFit: app.scoreFit,
		scoreMemAwarded: app.scoreMemAwarded,
		annualAward: app.annualAward,
		denyReason: app.denyReason,
		notes: app.notes,
		awardedMember: app.awardedMember,
		market: app.market,
		coLocation: app.coLocation,
		status501: app.status501,
		dateFounded: app.dateFounded,
		scoreLocation: app.scoreLocation,
		scoreChallenges: app.scoreChallenges,
		scoreFunding: app.scoreFunding,
		scoreEvents: app.scoreEvents,
		scoreVolunteers: app.scoreVolunteers,
		scoreOverallImpact: app.scoreOverallImpact,
		onboardingDate: app.onboardingDate || "",
	});


	//Locations
	const generateCohatchMarket = () => {
		const cohatchMarket = [];

		for (const market in COHATCH_MARKETS) {
			cohatchMarket.push(COHATCH_MARKETS[market]);
		}
		return cohatchMarket.flat();
	};

	const generateCohatchLocation = () => {
		if (market) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};

	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;
			setMarket(value);
			//handleChange("market", market);
			handleChange("coLocation", null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		} else {
			// setMarket(null);
			// handleChange("market", null);
			handleChange("coLocation", null);
		}
	};

	const handleLocationsChange = (event) => {
		if (event) {
			const { label } = event;

			handleChange("coLocation", label);
		} else {
			handleChange("coLocation", null);
		}
	};

	const statusOPTIONS = [
		{ value: "rejected", label: "Rejected" },
		{ value: "approved", label: "Approved" },
	];

	//Add Notes
	const [userNotes, setUserNotes] = useState(payload.notes || []);
	const [notes, setNotes] = React.useState([]);

	const [newNotes, setNewNotes] = useState(null);
	const handleAddNote = (event) => {
		console.log("inside notes");
		event.preventDefault();
		if (!newNotes) {
			return;
		}
		if (inArray(userNotes, newNotes)) {
			notify(`${newCause} already exists in your causes list.`, "error");
			return;
		}
		const addNote = newNotes.target.value;
		const prevNotes = app.notes;
		setUserNotes(() => {
			return [
				...prevNotes,
				`${addNote} - ${userState.firstName} ${userState.lastName
				} ${new Date().toLocaleDateString("en-US")}`,
			];
		});
		setPayload({
			...payload,
			notes: userNotes,
		});
		//setNewNotes(null);
	};

	// PAYLOAD
	const handleChange = (key, value) => {
		setPayload({
			...payload,
			[key]: value,
		});
	};
	const handleUpdate = (payload) => {
		if (payload.mlStatus === "approved") {
			setPayload({
				...payload,
				appStatus: "approved",
			});
			setAppApprovedModal(true);
		} else if (payload.cmStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else if (payload.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else {
			setPayload({
				...payload,
				appStatus: "pending",
			});
			handleSubmit(payload);
		}
	};
	const handleResendEmails = (payload) => {
		if (app.mlStatus === "approved") {
			setPayload({
				...payload,
				appStatus: "approved",
			});
			setAppApprovedModal(true);
		} else if (app.cmStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else if (app.cmStatus === "approved" && app.mlStatus === "rejected") {
			setPayload({
				...payload,
				appStatus: "rejected",
			});
			setAppDeniedModal(true);
		} else {
			setPayload({
				...payload,
				appStatus: "pending",
			});
			handleSubmit(payload);
		}
	};
	// END PAYLOAD

	React.useEffect(() => {
		setPayload({
			...payload,
			market: market,
		});
	}, [market]);
	React.useEffect(() => {
		setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
	}, [scoreMemAwarded]);
	React.useEffect(() => {
		setPayload({
			...payload,
			awardedMember: awardedMember,
		});
	}, [awardedMember]);

	React.useEffect(() => {
		setPayload({
			...payload,
			scoreMemAwarded: scoreMemAwarded,
		});
	}, [scoreMemAwarded]);
	React.useEffect(() => {
		setPayload({
			...payload,
			awardedMember: awardedMember,
		});
	}, [awardedMember]);

	return (
		<>
			<div className="mt-10">
				<div className="modal-dialog modal-dialog-scrollable">
					<div className={`${open ? "block" : "hidden"} relative z-10 `}>
						<div className="fixed inset-0 bg-gray-100 bg-opacity-60 transition-opacity"></div>
						<div className="fixed z-10 inset-0 overflow-y-auto">
							<div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
								<div className="max-w-3xl mx-auto bg-white rounded-md shadow-md p-4 flex flex-col">
									<div className="flex cursor-pointer px-3">
										<button onClick={onClose}>
											<span className="material-icons-outlined text-4xl">
												chevron_left
											</span>
										</button>
									</div>

									<div className=" w-full sm:p-4 space-y-5">
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="name"
													className=" block text-sm font-medium text-gray-700"
												>
													First Name
												</label>
												<div className="mt-1">
													<input
														className="capitalize block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 focus:outline-none focus:ring-primary-400 focus:border-primary-400"
														type="text"
														defaultValue={app.firstName}
														onChange={(evt) => {
															handleChange("firstName", evt.target.value);
														}}
													/>
												</div>
											</div>

											<div className="w-full">
												<label
													htmlFor="name"
													className="block text-sm font-medium text-gray-700"
												>
													Last Name
												</label>
												<div className="mt-1">
													<input
														className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 focus:outline-none focus:ring-primary-400 focus:border-primary-400"
														type="text"
														name="name"
														id="name"
														onChange={(evt) => {
															handleChange("lastName", evt.target.value);
														}}
														defaultValue={app.lastName}
													/>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="jobTitle"
													className="block text-sm font-medium text-gray-700"
												>
													E-mail
												</label>
												<div className="mt-1">
													<input
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
														type="text"
														name="jobTitle"
														id="jobTitle"
														onChange={(evt) => {
															handleChange("email", evt.target.value);
														}}
														defaultValue={app.email}
													/>
												</div>
											</div>

											<div className="w-full">
												<label
													htmlFor="phone"
													className="block text-sm font-medium text-gray-700"
												>
													Phone Number
												</label>
												<div className="mt-1">
													<input
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
														type="tel"
														name="phone"
														id="phone"
														onChange={(evt) => {
															handleChange("phoneNumber", evt.target.value);
														}}
														defaultValue={app.phoneNumber}
													/>
												</div>
											</div>
										</div>

										<div className="w-full">
											<label
												htmlFor="location"
												className="block text-sm font-medium text-gray-700"
											>
												Location
											</label>
											<div className="mt-1">
												<div className="w-full mt-3 flex gap-3 mb-6">
													<div className="w-full">
														<SelectInput
															label="COhatch City:"
															name="market"
															id="market"
															options={generateCohatchMarket()}
															noOptionsMessage="No cities found"
															placeholder={Market}
															setSelectedValue={handleMarketChange}
														/>
													</div>
													<div className="w-full flex  gap-3">
														<div className="w-full ">
															<SelectInput
																label="COhatch Location"
																id="coLocation"
																name="coLocation"
																options={generateCohatchLocation()}
																setSelectedValue={handleLocationsChange}
																placeholder={Colocation}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="date"
													className="block text-sm font-medium text-gray-700"
												>
													Application Submission Date
												</label>
												<div className="mt-1">
													<time
														dateTime=""
														className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md"
													>
														{format(new Date(createdAt), "MMMM do, yyyy")}
													</time>
												</div>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="onboardingDate"
													className="block text-sm font-medium text-gray-700"
												>
													Onboarding Date
												</label>
												<div className="mt-1">
													<input
														type="date"
														id="onboardingDate"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
														value={payload.onboardingDate}
														onChange={(evt) => handleChange("onboardingDate", evt.target.value)}
													/>
												</div>
											</div>
										</div>;

										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											{app.type === "boost" && (
												<div className="w-full">
													<label
														htmlFor="mlStatus"
														className="block text-sm font-medium text-gray-700"
													>
														Date Founded
													</label>
													<div className="mt-1">
														<input
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md text-gray-500"
															type="tel"
															name="phone"
															id="phone"
															onChange={(evt) => {
																handleChange("dateFounded", evt.target.value);
															}}
															defaultValue={app.dateFounded}
														/>
													</div>
												</div>
											)}
											<div className="w-full">
												<button
													type="button"
													onClick={() => {
														handleResendEmails(payload);
														onClose();
													}}
													className="mt-6 inline-flex justify-center rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Resend Application Status Email to Scholar
												</button>
											</div>
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											{userState.role[0] === "COMMUNITY_MANAGER" ||
												userState.role[0] === "MASTER_APPROVAL" ? (
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-sm font-medium text-gray-700"
													>
														Community Manager Approval Status
													</label>
													<div className="mt-1">
														{app.cmStatus === "pending" ? (
															<SelectInput
																className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md capitalize"
																placeholder={String(app.cmStatus).toUpperCase()}
																options={statusOPTIONS}
																setSelectedValue={(evt) =>
																	handleChange("cmStatus", evt.value)
																}
															/>
														) : (
															<div className="mt-1">
																<div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
																	{String(app.cmStatus).toUpperCase()}
																</div>
															</div>
														)}
													</div>
												</div>
											) : (
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-sm font-medium text-gray-700"
													>
														Community Manager Approval Status
													</label>
													<div className="mt-1">
														<div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
															{String(app.cmStatus).toUpperCase()}
														</div>
													</div>
												</div>
											)}

											{userState.role[0] === "MARKET_LEADER" ||
												userState.role[1] === "MARKET_LEADER" ||
												userState.role[0] === "MASTER_APPROVAL" ? (
												<div className="w-full">
													<label
														htmlFor="linkedin"
														className="block text-sm font-medium text-gray-700"
													>
														Market Leader Approval Status
													</label>
													<div className="mt-1">
														{app.mlStatus === "pending" &&
															app.cmStatus != "pending" ? (
															<SelectInput
																className="capitalize appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
																options={statusOPTIONS}
																setSelectedValue={(evt) =>
																	handleChange("mlStatus", evt.value)
																}
																placeholder={String(app.mlStatus).toUpperCase()}
															/>
														) : (
															<div className="mt-1">
																<div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
																	{String(app.mlStatus).toUpperCase()}
																</div>
															</div>
														)}
													</div>
												</div>
											) : (
												<div className="w-full">
													<label
														htmlFor="mlStatus"
														className="block text-sm font-medium text-gray-700"
													>
														Market Leader Approval Status
													</label>
													<div className="mt-1">
														<div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
															{String(app.mlStatus).toUpperCase()}
														</div>
													</div>
												</div>
											)}
										</div>
										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											{app.type === "give" && (
												<div className="w-full">
													<label
														htmlFor="facebook"
														className="block text-sm font-medium text-gray-700"
													>
														501C3 Approved
													</label>
													<div className="mt-1">
														<SelectInput
															className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
															options={statusOPTIONS}
															placeholder={String(app.status501).toUpperCase()}
															setSelectedValue={(evt) => {
																handleChange("status501", evt.value);
															}}
														/>
													</div>
												</div>
											)}

											<div className="w-full">
												<label
													htmlFor="linkedin"
													className="block text-sm font-medium text-gray-700"
												>
													OfficeRnD Status
												</label>
												<div className="mt-1">
													<SelectInput
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														options={statusOPTIONS}
														// placeholder="Select an option"
														setSelectedValue={(evt) => {
															handleChange("officeRnd", evt.value);
														}}
														//value={app.admin.mlStatus}
														placeholder={String(app.officeRnd).toUpperCase()}
													/>
												</div>
											</div>
										</div>

										<div className="w-full space-y-3 sm:flex sm:justify-between sm:gap-3 sm:space-y-0">
											<div className="w-full">
												<label
													htmlFor="bio"
													className="block text-sm font-medium text-gray-700"
												>
													Notes
												</label>
												<div>
													<textarea
														className="text-left whitespace-nowrap text-sm  appearance-none block px-3 py-2 w-full border border-gray-600 rounded-md "
														type="text"
														rows="1"
														placeholder="add new note..."
														onChange={(evt) => {
															setNewNotes(evt);
														}}
													/>
												</div>
											</div>

											<div className="">
												<button
													className="mt-5 cursor-pointer rounded-md bg-green-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
													type="submit"
													icon="add"
													size="md"
													onClick={handleAddNote}
												>
													+
												</button>
											</div>
										</div>
									</div>

									<div className="mt-1">
										<div className="w-full flex items-center gap-3">
											<div className="w-full">
												<div className="mt-1">
													<div className="text-left whitespace-nowrap text-sm  appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md">
														{/* show app.notes as default. But if payload .notes are present, replace with payload. notes. */}

														{payload.notes
															? payload.notes?.map((notes, index) => (
																<div key={uuid()}>
																	<div key={index}>{notes}</div>
																</div>
															))
															: app.notes?.map((notes, index) => (
																<div key={uuid()}>
																	<div key={index}>{notes}</div>
																</div>
															))}
													</div>
												</div>
											</div>
											<div></div>
										</div>
									</div>

									<div className="w-full flex justify-end">
										<div className="mt-3">
											<button
												className="ml-6 rounded-md border border-transparent bg-primary-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
												type="button"
												onClick={() => {
													handleUpdate(payload);
													onClose();
												}}
											>
												Save Changes
											</button>;
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditApplicationModal;
